package pages;

import mui.core.CircularProgress;
import mui.core.Container;
import mui.core.Paper;
import react.ReactComponent;
import react.ReactMacro.jsx;

class Loading extends ReactComponent {

	override function render():ReactFragment {
		return jsx('
		<Container style={{width: "80%"}}>
			<Paper variant={Outlined} square style={{marginTop: "-8vh", padding: "0"}}>
				<Container><CircularProgress disableShrink  /></Container>
			</Paper>
		</Container>
		');
	}
}
