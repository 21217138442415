package pages;

import mui.core.Paper;
import mui.core.Accordion;
import mui.core.Box;
import mui.core.Typography;
import mui.core.Container;
import mui.core.Avatar;
import mui.core.ListItemAvatar;
import mui.core.ListItemText;
import mui.core.styles.Classes;
import mui.core.styles.MuiTheme.DefaultTheme;
import firebase.web.app.FirebaseApp;
import shared.TStoreContent;
import react.ReactComponent;
import react.ReactMacro.jsx;
import mui.icon.*;
import mui.core.List;
import mui.core.ListItem;
import mui.core.Divider;
import react.router.Link;

private typedef Props = {
	var topic:String;
	var classes:TClasses;
}

private typedef State = {
	@:optional var topic:String;
	@:optional var panel:String;
	var threads:Array<TStoreContent>;
}

private typedef TClasses = Classes<[heading, accor_root, expanded, summary_root, details_root, content]>;


@:wrap(Styles.withStyles(styles))
class HomePage extends ReactComponentOfPropsAndState<Props, State> {
	public static function styles(theme:DefaultTheme):ClassesDef<TClasses> {
		return Styles.jss({
			accor_root: {
				border: '1px solid rgba(0, 0, 0, .125)',
				boxShadow: 'none',
				'&:not(:last-child)': {
					borderBottom: '0',
				},
				'&:before': {
					display: 'none',
				},
				'&.$expanded': {
					margin: 'auto',
				},
			},
			summary_root: {
				backgroundColor: 'rgba(0, 0, 0, .03)',
				borderBottom: '1px solid rgba(0, 0, 0, .125)',
				margin: 'auto',
				minHeight: 45,
				marginBottom: -1,
				'&.$expanded': {
					minHeight: 45,
				},
			},
			details_root: {
				padding: theme.spacing(2) + '!important'
			},
			content: {
			},
			expanded: {
				minHeight: 'auto!important',
				margin: 'auto!important'
			},
			heading: {
				fontSize: theme.typography.pxToRem(15),
				fontWeight: theme.typography.fontWeightRegular,
			}
		});
	}

	function new(props) {
		super(props);
		this.state = {
			threads: [],
			topic: "",
			panel: "general",
		};
	}

	function getThreads() {
		// var q:Query<TStoreContent> = query(collection(db, 'test'), orderBy('timestamp', DESCENDING), where('topic', '==', this.props.topic));
		// Firestore.getDocs(q).then((docs) -> {
		// 	var arr = [];
		// 	trace(docs.size);
		// 	docs.forEach((doc) -> {
		// 		arr.push(doc.data());
		// 		if (docs.size == arr.length) {
		// 			this.setState({threads: arr});
		// 			trace(arr);
		// 		}
		// 	});
		// }, err);
	}

	override function componentDidMount() {
		this.getThreads();
	}

	function renderThreads() {
		var output = [];
		for (thread in this.state.threads) {
			var date = thread.timestamp.toDate().toString();
			output.push(jsx('
			<div>
				<ListItem button>
					<ListItemAvatar>
						<Avatar>
							<Image />
						</Avatar>
					</ListItemAvatar>
          <ListItemText primary=${thread.title} secondary=${date} />
        </ListItem>
				<Divider />
				</div>
			'));
		}

		return output;
	}

	function onChange(id:String, event, value) {
		if (this.state.panel == id) {
			id = "";
		}
		this.setState({panel: id});
	}

	function setTopic(id:String) {
		this.setState({topic: id});
	}

	override function render():ReactFragment {
		return jsx('
		<Container>
			<Paper variant={Outlined} square style={{marginTop: "-8vh", padding: "0"}}>
				<Accordion expanded={true} onChange={this.onChange.bind("general")} square classes={{root: classes.accor_root, expanded: classes.expanded}}>
					<AccordionSummary 
						classes={{root: classes.summary_root, content: classes.content, expanded: classes.expanded}}
						expandIcon={<ExpandMore />}
					>
					<Box>
						<Typography variant={H6}>Discord Conversations</Typography>
						<Typography variant={Subtitle2}>A list of threads being discussed on the haxe discord!</Typography>
					</Box>
					</AccordionSummary>
					<AccordionDetails classes={{root: classes.details_root}}>
						<Box style={{width: "100%"}}>
							<Typography variant={Subtitle2}>General Haxe</Typography>
							<List component="nav">
								<ListItem component={Link} to="/haxe" button onClick=${this.setTopic.bind('haxe')}>
									<ListItemAvatar>
										<Avatar>
											<Image />
										</Avatar>
									</ListItemAvatar>
									<ListItemText primary="Haxe" secondary="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra arcu at est rutrum, ut bibendum diam dapibus. Cras faucibus arcu vitae massa condimentum" />
								</ListItem>
								<ListItem component={Link} to="/haxeui" button onClick=${this.setTopic.bind('haxeui')}>
									<ListItemAvatar>
										<Avatar>
											<Image />
										</Avatar>
									</ListItemAvatar>
									<ListItemText primary="HaxeUI" secondary="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra arcu at est rutrum, ut bibendum diam dapibus. Cras faucibus arcu vitae massa condimentum" />
								</ListItem>
								<ListItem component={Link} to="/tools" button onClick=${this.setTopic.bind('tools')}>
									<ListItemAvatar>
										<Avatar>
											<Image />
										</Avatar>
									</ListItemAvatar>
									<ListItemText primary="Tools" secondary="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra arcu at est rutrum, ut bibendum diam dapibus. Cras faucibus arcu vitae massa condimentum" />
								</ListItem>
								</List>
								<Typography variant={Subtitle2}>Game Frameworks</Typography>
								<List>
								<ListItem component={Link} to="/flixel" button onClick=${this.setTopic.bind('flixel')}>
									<ListItemAvatar>
										<Avatar>
											<Image />
										</Avatar>
									</ListItemAvatar>
									<ListItemText primary="Flixel" secondary="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra arcu at est rutrum, ut bibendum diam dapibus. Cras faucibus arcu vitae massa condimentum" />
								</ListItem>
								<ListItem component={Link} to="/heaps" button onClick=${this.setTopic.bind('heaps')}>
									<ListItemAvatar>
										<Avatar>
											<Image />
										</Avatar>
									</ListItemAvatar>
									<ListItemText primary="Heaps" secondary="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra arcu at est rutrum, ut bibendum diam dapibus. Cras faucibus arcu vitae massa condimentum" />
								</ListItem>
								<ListItem component={Link} to="/ceramic" button onClick=${this.setTopic.bind('ceramic')}>
									<ListItemAvatar>
										<Avatar>
											<Image />
										</Avatar>
									</ListItemAvatar>
									<ListItemText primary="Ceramic" secondary="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra arcu at est rutrum, ut bibendum diam dapibus. Cras faucibus arcu vitae massa condimentum" />
								</ListItem>
								<ListItem component={Link} to="/openfl" button onClick=${this.setTopic.bind('openfl')}>
									<ListItemAvatar>
										<Avatar>
											<Image />
										</Avatar>
									</ListItemAvatar>
									<ListItemText primary="Openfl" secondary="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra arcu at est rutrum, ut bibendum diam dapibus. Cras faucibus arcu vitae massa condimentum" />
								</ListItem>
							</List>
						</Box>
					</AccordionDetails>
				</Accordion>
			</Paper>
		</Container>
		');
	}
	var classes(get, never):TClasses;

	function get_classes():TClasses {
		return props.classes;
	}

	var db(get, never):Firestore;
	private inline function get_db() {
		return Firestore.getFirestore(FirebaseApp.getApp());
	}
}
