package util;

function err(err) {
	trace(err);
}

function capitalise(str:String) {
	if (str == null) {
		return null;
	}
	return str.substr(0, 1).toUpperCase() + str.substr(1);
}