package pages;

import firebase.web.firestore.DocumentReference;
import mui.core.AppBar;
import shared.TSession;
import shared.HelpState;
import mui.core.DialogContent;
import mui.core.DialogTitle;
import mui.core.Toolbar;
import mui.core.IconButton;
import mui.core.Container;
import mui.core.Avatar;
import mui.core.ListItemAvatar;
import mui.core.ListItemText;
import mui.core.styles.Classes;
import mui.core.styles.MuiTheme.DefaultTheme;
import firebase.web.app.FirebaseApp;
import shared.TStoreContent;
import react.ReactComponent;
import react.ReactMacro.jsx;
import mui.icon.*;
import mui.core.List;
import mui.core.ListItem;
import react.router.Link;

private typedef Props = {
	var classes:TClasses;
	var closeDialog:Void->Void;
	var topic:String;
	var id:Int;
	@:optional var content:TStoreContent;
}

private typedef State = {
	@:optional var id:Int;
	@:optional var content:TStoreContent;
}

private typedef TClasses = Classes<[appBar, title]>;

@:wrap(Styles.withStyles(styles))
class ThreadDisplay extends ReactComponentOfPropsAndState<Props, State> {
	public static function styles(theme:DefaultTheme):ClassesDef<TClasses> {
		return Styles.jss({
			appBar: {
				position: Relative,
			},
			title: {
				marginLeft: theme.spacing(2),
				flex: "1",
			}
		});
	}

	function new(props) {
		super(props);
		this.state = {
			content: null,
			id: -1
		}
	}

	function ListItemLink(props) {
		return jsx('<ListItem	button component="a" {...props} />');
	}

	function renderMessages() {
		var messages = [];
		for (key => message in this.content.discussion) {
			var secondary = message.user.username;
			var posted = DateTools.format(message.posted.toDate(), '%H:%M %d-%m-%Y');
			secondary += ' | $posted';
			messages.push(jsx('
			<ListItem key=$key>
				<ListItemAvatar>
					<Avatar src=${message.user.avartarURL}>
						<Image />
					</Avatar>
				</ListItemAvatar>
				<ListItemText primary=${message.content} secondary=${secondary} />
			</ListItem>
			'));
		}
		return messages;
	}

	function getContent(?id:String) {
		throw 'update to grab via id rather than firebase id';
		var q:DocumentReference<TStoreContent> = doc(db, 'test2', this.props.topic, 'threads', id);

		Firestore.getDoc(q).then(function(doc) {
			trace(doc);
			
			if (doc.exists()) {
				this.setState({content: doc.data()});
			}
			return;
		}, err);
	}

	function renderAnswers() {
		var data = this.content;
		var arr = [];
		for (key => item in data.session.questions) {
			var state = item.state;
			var answer = item.answer;
			
			if (item.state == title) {
				continue;
			}
			
			if (item.state == question_type) {
				answer = (answer:QuestionType);
			}

			arr.push(jsx('
			<ListItem key=$key>
				<ListItemText 
					primary=${item.question} 
					secondary=${answer} 
				/>
			</ListItem>
			'));
		}

		if (this.content.solution != null && this.content.solution.description != null) {
			arr.push(jsx('
			<ListItem key="-1">
				<ListItemText
					primary="Solution summary"
					secondary=${this.content.solution.description}
				/>
			</ListItem>
			'));
		}

		arr.push(jsx('
		<ListItem key="-2">
			<ListItemText
				primary="${this.content.author.name}"
				secondary=${this.content.timestamp.toDate().toString()}
			/>
		</ListItem>
		'));
		
		return arr;
	}

	override function render():ReactFragment {
		if (this.content == null) {
			return jsx('<div></div>');
		}
		var title = this.getResponseFromSession(title);

		return jsx('
		<div >
			<AppBar className={classes.appBar}>
				<Toolbar>
					<IconButton edge=${Start} color=${Inherit} component={Link} to="/${this.props.topic}" onClick=${this.props.closeDialog}>
						<Close />
					</IconButton>
					<DialogTitle id="max-width-dialog-title">${(title.answer)}</DialogTitle>
				</Toolbar>
			</AppBar>
			<DialogContent style={{marginTop: -10}}>
				<Container style={{width: "75%", minHeight: "95vh", backgroundColor: "#0000004D"}}>
					<List dense>
						${this.renderAnswers()}
					</List>
					<List>
						${this.renderMessages()}
					</List>
				</Container>
			</DialogContent>
		</div>
		');
	}

	function getResponseFromSession(state:HelpState) {
		if (this.content == null) {
			return null;
		}

		for (item in this.content.session.questions) {
			if (item.state == state) {
				return item;
			}
		}

		return null;
	}

	var classes(get, never):TClasses;

	function get_classes():TClasses {
		return props.classes;
	}

	var content(get, never):TStoreContent;

	private inline function get_content() {
		if (this.props.content != null) {
			return this.props.content;
		}

		return this.state.content;
	}

	var db(get, never):Firestore;

	private inline function get_db() {
		return Firestore.getFirestore(FirebaseApp.getApp());
	}
}
