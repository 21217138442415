package pages;

import mui.core.Divider;
import mui.core.Breadcrumbs;
import shared.TSession.QuestionType;
import pages.Loading;
import mui.core.CardContent;
import mui.core.Card;
import mui.core.Paper;
import mui.core.Typography;
import mui.core.Container;
import react.router.ReactRouter;
import shared.HelpState;
import react.React;
import mui.core.Slide;
import mui.core.Dialog;
import mui.core.Avatar;
import mui.core.ListItemAvatar;
import mui.core.ListItemText;
import mui.core.styles.Classes;
import mui.core.styles.MuiTheme.DefaultTheme;
import firebase.web.app.FirebaseApp;
import shared.TStoreContent;
import react.ReactComponent;
import react.ReactMacro.jsx;
import mui.icon.*;
import mui.core.List;
import mui.core.ListItem;
import mui.core.Box;
import history.Location;
import react.router.Link;
import react.router.NavLink;
import mui.core.Link as MuiLink;
import mui.TextColor;
import externs.ReactMarkdown;

private typedef Props = {
	var id:Int;
	var topic:String;
	var thread:TStoreContent;
	var classes:TClasses;
	var setThread:(thread:TStoreContent) -> Void;
}

private typedef State = {
	@:optional var id:Int;
	@:optional var topic:String;
	@:optional var dialog_open:Bool;
	@:optional var thread:TStoreContent;
	@:optional var threads:Map<Int, TStoreContent>;
}

private typedef TClasses = Classes<[root, link, icon]>;

@:wrap(Styles.withStyles(styles))
class ThreadDiscussionPage extends ReactComponentOfPropsAndState<Props, State> {
	public static function styles(theme:DefaultTheme):ClassesDef<TClasses> {
		return Styles.jss({
			root: {
				width: '100%'
			},
			link: {
				display: 'block',
			},
			icon: {
				marginRight: theme.spacing(0.5),
				width: 20,
				height: 20,
			},
		});
	}

	function new(props) {
		super(props);
		this.state = {
			id: -1,
			topic: "",
			threads: null,
			dialog_open: false
		};
	}

	function getThreads() {
		if (this.topic == null || this.id == null) {
			return;
		}

		if (this.thread != null && this.thread.id == this.id) {
			return;
		}

		var q:Query<TStoreContent> = query(collection(db, 'test2', this.topic, 'threads'), where('valid', '==', true), where('id', '==', this.id),
			orderBy('timestamp', DESCENDING));
		Firestore.getDocs(q).then((docs) -> {
			if (docs.size == 0) {
				return;
			}	

			this.setState({thread: docs.docs[0].data()});
		}, err);
	}

	override function componentDidMount() {
		this.getThreads();
	}

	override function componentDidUpdate(prevProps, prevState) {
		if (this.props.topic != prevProps.topic) {
			this.getThreads();
		}
	}

	function renderAnswers() {
		var arr = [];
		for (key => item in this.thread.session.questions) {
			var state = item.state;
			var answer = item.answer;

			if (item.state == title) {
				continue;
			}

			if (item.state == question_type) {
				answer = (answer : QuestionType);
			}

			arr.push(jsx('
			<ListItem key=$key>
				<ListItemText 
					primary=${item.question} 
					secondary=${answer} 
				/>
			</ListItem>
			'));
		}

		if (this.thread.solution != null && this.thread.solution.description != null) {
			arr.push(jsx('
			<ListItem key="-1">
				<ListItemText
					primary="Solution summary"
					secondary=${this.thread.solution.description}
				/>
			</ListItem>
			'));
		}
		var date = DateTools.format(this.thread.timestamp.toDate(), '%H:%M %d-%m-%Y');
		arr.push(jsx('
		<ListItem key="-2">
			<ListItemText
				primary=${this.thread.author.name}
				secondary=$date
			/>
		</ListItem>
		'));

		return arr;
	}

	function renderMessages() {
		var messages = [];
		var markdown = (content) -> {
			return jsx('<ReactMarkdown>$content</ReactMarkdown>');
		}

		for (key => message in this.thread.discussion) {
			var content = message.content;
			var secondary = message.user.username;
			var posted = DateTools.format(message.posted.toDate(), '%H:%M %d-%m-%Y');
			secondary += ' | $posted';
			messages.push(jsx('
			<ListItem key=$key>
				<ListItemAvatar>
					<Avatar src=${message.user.avartarURL}>
						<Image />
					</Avatar>
				</ListItemAvatar>
				<ListItemText primary={<ReactMarkdown>$content</ReactMarkdown>} secondary=${secondary} />
			</ListItem>
			'));
		}
		return messages;
	}

	override function render():ReactFragment {
		var content = jsx('<Container><Loading /></Container>');
		if (this.thread != null) {
			content = jsx('
			<Container>
				<Paper variant={Outlined} square style={{padding: "5px"}}>
					<Breadcrumbs>
						<MuiLink color={Inherit} to="/" component={Link}>
							Home
						</MuiLink>
						<MuiLink color={Inherit} to="/haxe" component={Link}>
							${this.topic.capitalise()}
						</MuiLink>
						<MuiLink color={Inherit}>
							Thread #${this.thread.id}
						</MuiLink>
					</Breadcrumbs>
				</Paper>
				<Paper variant={Outlined} square style={{marginTop: "1vh", padding: "5px"}}>
					<Container style={{width: "100%"}}>
						<Typography variant={H5}>THREAD INFO</Typography>
						<List component="nav">
							${this.renderAnswers()}
						</List>
					</Container>
				</Paper>

				<Paper variant={Outlined} square style={{marginTop: "1vh", marginBottom: "1vh", padding: "5px"}}>
					<Container style={{width: "100%"}}>
						<Typography variant={H5}>DISCUSSION</Typography>
						<List>
							${this.renderMessages()}
						</List>
					</Container>
				</Paper>
			</Container>
			');
		}
		return jsx('
		<Box style={{marginTop: "1vh"}}>
			$content
		</Box>
		');

	}

	var thread(get, never):TStoreContent;

	function get_thread() {
		var thread = this.props.thread;
		if (thread == null) {
			return this.state.thread;
		}
		return thread;
	}

	var classes(get, never):TClasses;

	function get_classes():TClasses {
		return props.classes;
	}

	var id(get, never):Int;

	function get_id():Int {
		var id = this.props.id;
		return id;
	}

	var topic(get, never):String;

	function get_topic():String {
		var topic = this.props.topic;
		if (topic == null || topic.length == 0) {
			return null;
		}
		return topic;
	}

	var db(get, never):Firestore;

	private inline function get_db() {
		return Firestore.getFirestore(FirebaseApp.getApp());
	}

	function getResponseFromSession(title:TStoreContent, state:HelpState) {
		for (item in title.session.questions) {
			if (item.state == state) {
				return item;
			}
		}
		return null;
	}
}
