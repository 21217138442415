package pages;

import mui.core.Breadcrumbs;
import pages.Loading;
import mui.core.CardContent;
import mui.core.Card;
import mui.core.Paper;
import mui.core.Typography;
import mui.core.Container;
import react.router.ReactRouter;
import shared.HelpState;
import react.React;
import mui.core.Slide;
import mui.core.Dialog;
import mui.core.Avatar;
import mui.core.ListItemAvatar;
import mui.core.ListItemText;
import mui.core.styles.Classes;
import mui.core.styles.MuiTheme.DefaultTheme;
import firebase.web.app.FirebaseApp;
import shared.TStoreContent;
import react.ReactComponent;
import react.ReactMacro.jsx;
import mui.icon.*;
import mui.core.List;
import mui.core.ListItem;
import mui.core.Box;
import history.Location;
import react.router.Link;
import mui.TextColor;
import mui.core.Link as MuiLink;

private typedef Props = {
	var topic:String;
	var classes:TClasses;
	var setThread:(thread:TStoreContent)->Void;
}

private typedef State = {
	@:optional var thread:TStoreContent;
	@:optional var threads:Map<Int, TStoreContent>;
}

private typedef TClasses = Classes<[root]>;

@:wrap(Styles.withStyles(styles))
class ThreadsPage extends ReactComponentOfPropsAndState<Props, State> {
	public static function styles(theme:DefaultTheme):ClassesDef<TClasses> {
		return Styles.jss({
			root: {
				width: '100%'
			}
		});
	}

	function new(props) {
		super(props);
		this.state = {
			threads: null,
		};
	}

	function getThreads() {
		if (this.topic == null) {
			return;
		}
		var q:Query<TStoreContent> = query(collection(db, 'test2', this.topic, 'threads'), where('valid', '==', true), orderBy('timestamp', DESCENDING));
		Firestore.getDocs(q).then((docs) -> {

			var length = 1;
			var map = new Map<Int, TStoreContent>();
			if (docs.size == 0) {
				this.setState({threads: map});
				return;
			}
			for (item in docs.docs) {
				var data = item.data();
				map.set(data.id, data);
				if (docs.size == length) {
					this.setState({threads: map});
				}
				length++;
			}
		}, err);
	}

	override function componentDidMount() {
		this.getThreads();
	}

	override function componentDidUpdate(prevProps, prevState) {
		if (this.props.topic != prevProps.topic) {
			this.getThreads();
		}
	}

	function renderThreads() {
		var output = [];
		for (key => thread in this.state.threads) {
			var question = this.getResponseFromSession(thread, title);
			if (question == null) {
				continue;
			}
			var date = DateTools.format(thread.timestamp.toDate(), "%H:%M %d-%m-%Y");
			var by = 'By: ${thread.author.name} | $date ';
			output.push(jsx('
			<ListItem key=${key} component={Link} to="/${this.topic}/${thread.id}" button onClick=${this.props.setThread.bind(thread)}>
				<ListItemAvatar>
					<Avatar>
						<Image />
					</Avatar>
				</ListItemAvatar>
				<ListItemText primary=${question.answer} secondary=${by} />
			</ListItem>
			'));
		}

		if (output.length == 0) {
			output.push(jsx('<ListItem key={0}>No threads.</ListItem>'));
		}

		return output;
	}

	override function render():ReactFragment {
		var content = jsx('<Loading />');
		if (this.state.threads != null) {
			content = jsx('
				<List component="nav">
					${this.renderThreads()}
				</List>
			');
		}

		var topic = '';
		if (this.topic != null) {
			topic = this.topic.capitalise();
		}

		return jsx('
		<Box style={{marginTop: "1vh"}}>
			<Container>
				<Paper variant={Outlined} square style={{padding: "5px", marginBottom: "1vh"}}>
					<Breadcrumbs>
						<MuiLink color={Inherit} to="/" component={Link}>
							Home
						</MuiLink>
						<MuiLink color={Inherit}>
							${topic}
						</MuiLink>
					</Breadcrumbs>
				</Paper>
				<Paper variant={Outlined} square style={{padding: "10px"}}>
					<Container style={{width: "100%"}}>
					<Typography variant={H5}>${topic.toUpperCase()} THREADS</Typography>
					$content
					</Container>
				</Paper>
			</Container>
		</Box>
		');
	}

	var classes(get, never):TClasses;

	function get_classes():TClasses {
		return props.classes;
	}

	var topic(get, never):String;

	function get_topic():String {
		var topic = this.props.topic;
		if (topic == null || topic.length == 0) {
			return null;
		}
		
		return topic;
	}

	var db(get, never):Firestore;

	private inline function get_db() {
		return Firestore.getFirestore(FirebaseApp.getApp());
	}

	function getResponseFromSession(title:TStoreContent, state:HelpState) {
		for (item in title.session.questions) {
			if (item.state == state) {
				return item;
			}
		}
		return null;
	}
}
