package;

import mui.core.Button;
import pages.ThreadDiscussionPage;
import shared.TStoreContent;
import react.router.Routes;
import pages.ThreadsPage;
import react.router.Route;
import react.router.Switch;
import mui.core.Paper;
import mui.core.Container;
import mui.core.IconButton;
import pages.HomePage;
import mui.core.styles.MuiThemeProvider;
import history.Location;
import react.React;
import react.router.ReactRouter;
import react.router.Route.RouteRenderProps;
import pages.ListThreads;
import js.html.Event;
import react.router.BrowserRouter;
import mui.icon.*;
import mui.core.AppBar;
import mui.core.Typography;
import mui.core.Toolbar;
import mui.core.styles.MuiTheme;
import mui.icon.Link as LinkIcon;
import react.router.Link;
import js.Browser;
import react.ReactComponent;
import react.ReactDOM;
import react.ReactMacro.jsx;
import mui.core.styles.Classes;
import firebase.web.app.FirebaseApp;
import mui.core.Box;

using Std;

class Main {
	public static var app:FirebaseApp;

	static function main() {
		Main.app = FirebaseApp.initializeApp({
			"apiKey": "AIzaSyBKGASqr3dgNygiv3WnHzQKYv_iYTwK4Kk",
			"authDomain": "haxe-forum.firebaseapp.com",
			"projectId": "haxe-forum",
			"storageBucket": "haxe-forum.appspot.com",
			"messagingSenderId": "72566195073",
			"appId": "1:72566195073:web:2850b70809f24f6ca79e80",
			"measurementId": "G-2T35ZXYLPB",
			"databaseURL": "https://console.firebase.google.com/u/0/project/haxe-forum"
		});

		var theme = MuiTheme.createMuiTheme({
			palette: {
				type: Light
			}
		});
		Browser.document.body.style.backgroundColor = "#ced7db";
		Browser.document.body.style.margin = "0";
		ReactDOM.render(jsx('<BrowserRouter><MuiThemeProvider theme={theme}><App/></MuiThemeProvider></BrowserRouter>'),
			Browser.document.getElementById("root"));
	}
}

private typedef State = {
	@:optional var id:Int;
	@:optional var toolbar:Int;
	@:optional var topic:String;
	@:optional var thread:TStoreContent;

	@:optional var anchor:Dynamic;
	@:optional var is_open:Bool;
	@:optional var drawer_open:Bool;
	@:optional var location:Location;
}

private typedef TClasses = Classes<[tabs, grow, tabText, appBar, toolbar, search, searchIcon, inputRoot, inputInput]>;

private typedef Props = {
	> RouteRenderProps,
	var classes:TClasses;
}

var theme = MuiTheme.createMuiTheme({
	palette: {
		type: Light
	}
});

@:expose('App')
@:wrap(Styles.withStyles(styles.bind(theme)))
class App extends ReactComponentOf<Props, State> {
	public static function styles(theme:DefaultTheme):ClassesDef<TClasses> {
		return Styles.jss({
			tabs: {
				borderRight: '1px solid ${theme.palette.divider}',
			},
			tabText: {
				alignSelf: Start
			},
			toolbar: {
				minHeight: 156,
				alignItems: FlexStart,
				paddingTop: theme.spacing(1),
				paddingBottom: theme.spacing(2),
			},
			appBar: {
				margin: 0
			},
			grow: {
				flexGrow: 1,
			},
			search: {
				position: Relative,
				borderRadius: theme.shape.borderRadius,
				marginRight: theme.spacing(2),
				marginLeft: 0,
				width: '20%',
				backgroundColor: alpha(theme.palette.common.white, 0.15),
				'&:hover': {
					backgroundColor: alpha(theme.palette.common.white, 0.25),
				},
			},
			searchIcon: {
				padding: theme.spacing(0, 2),
				height: '100%',
				position: Absolute,
				pointerEvents: None,
				display: 'flex',
				alignItems: Center,
				justifyContent: Center,
			},
			inputRoot: {
				color: 'inherit',
			},
			inputInput: {
				padding: theme.spacing(1, 1, 1, 0),
				// vertical padding + font size from searchIcon
				paddingLeft: 'calc(1em + ${theme.spacing(4)}px)',
				transition: theme.transitions.create(),
				width: '80%'
			}
		});
	}

	public function new(props) {
		super(props);

		state = {
			is_open: false,
			drawer_open: false,
			anchor: null,
			id: 6,
			topic: "",
			thread: null,
			toolbar: 156,
		}
	}

	function closeMenu() {
		this.setState({
			is_open: false,
			anchor: null
		});
	}

	function handleChange(event:Event, data:Any) {
		this.setState({
			id: data
		});
	}

	function Location() {
		var location = ReactRouter.useLocation();
		React.useEffect(function() {
			var split = location.pathname.split('/');
			var topic = null;
			var id = -1;
			if (split.length >= 2) {
				topic = split[1];
			}

			if (split.length >= 3) {
				id = Std.parseInt(split[2]);
			}

			var toolbar = 156;
			if (id != -1) {
				toolbar = 60;
			}

			if (topic != null && topic.length > 0) {
				toolbar = 60;
			}

			this.setState({
				location: location,
				id: id,
				topic: topic,
				toolbar: toolbar
			});
		}, [location]);
		return jsx('<div />');
	}

	function setThread(thread:TStoreContent) {
		this.setState({thread: thread});
	}

	override function render() {
		return jsx('
		<Box>			
		<Location />
			<AppBar position={Static}>
				<Toolbar className={classes.toolbar} style={{minHeight: this.state.toolbar}}>
					<IconButton edge={Start} color={Inherit} disabled>
						<Menu />
					</IconButton>
					<Typography style={{marginTop: "6px"}} variant=${H5}>HaxeIndex</Typography>
					<div className={classes.grow} />
					<IconButton color={Inherit} disabled>
						<Search />
					</IconButton>
					<IconButton edge={End} color={Inherit} disabled>
						<More />
					</IconButton>
				</Toolbar>
			</AppBar>

			<Switch>
				<Routes>
					<Route path="/" element={<HomePage />} />
					<Route path="/:topic" element={<ThreadsPage topic=${this.state.topic} setThread={this.setThread} />} />
					<Route path="/:topic/:id" element={<ThreadDiscussionPage id={this.state.id} thread={this.state.thread} topic=${this.state.topic} />} />
				</Routes>
			</Switch>
		</Box>
		');

	}

	public var classes(get, never):TClasses;

	function get_classes():TClasses {
		return props.classes;
	}
}