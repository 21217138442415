package pages;

import react.router.ReactRouter;
import shared.HelpState;
import react.React;
import mui.core.Slide;
import mui.core.Dialog;
import mui.core.Avatar;
import mui.core.ListItemAvatar;
import mui.core.ListItemText;
import mui.core.styles.Classes;
import mui.core.styles.MuiTheme.DefaultTheme;
import firebase.web.app.FirebaseApp;
import shared.TStoreContent;
import react.ReactComponent;
import react.ReactMacro.jsx;
import mui.icon.*;
import mui.core.List;
import mui.core.ListItem;
import mui.core.Box;
import history.Location;
import react.router.Link;

private typedef Props = {
	var location:Location;
	var topic:String;
	var classes:TClasses;
}

private typedef State = {
	@:optional var id:Int;
	@:optional var topic:String;
	@:optional var dialog_open:Bool;
	@:optional var thread:TStoreContent;
	@:optional var threads:Map<Int, TStoreContent>;
}

private typedef TClasses = Classes<[root]>;

@:wrap(Styles.withStyles(styles))
class ListThreads extends ReactComponentOfPropsAndState<Props, State> {
	public static function styles(theme:DefaultTheme):ClassesDef<TClasses> {
		return Styles.jss({
			root: {
				width: '100%'
			}
		});
	}

	function new(props) {
		super(props);
		this.state = {
			id: -1,
			topic: "",
			threads: [],
			dialog_open: false
		};
	}

	function getThreads() {
		var q:Query<TStoreContent> = query(collection(db, 'test2', this.topic, 'threads'), where('valid', '==', true), orderBy('timestamp', DESCENDING));
		Firestore.getDocs(q).then((docs) -> {
			var length = 1;
			var map = new Map<Int, TStoreContent>();
			for (item in docs.docs) {
				var data = item.data();
				map.set(data.id, data);
				if (docs.size == length) {
					this.setState({threads: map});
				}
				length++;
			}
		}, err);
	}

	override function componentDidMount() {
		this.getThreads();
	}

	override function componentDidUpdate(prevProps, prevState) {
		if (this.props.topic != prevProps.topic) {
			this.getThreads();
		}
	}

	function ListItemLink(props) {
		return jsx('<ListItem	button component = "a" {...props} />');
	}

	function openDialog(thread:Int) {
		this.setState({dialog_open: true, id: thread});
	}

	function closeDialog() {
		this.setState({dialog_open: false});
	}

	function Location() {
		var location = ReactRouter.useLocation();
		React.useEffect(function() {
			var split = location.pathname.split('/');
			var thread = split[2].parseInt();

			if (thread != null) {
				this.openDialog(thread);
			}
		}, [location]);
		return null;
	}

	function renderThreads() {
		var output = [];
		for (key => thread in this.state.threads) {
			var question = this.getResponseFromSession(thread, title);
			if (question == null) {
				continue;
			}
			var date = thread.timestamp.toDate().toString();

			output.push(jsx('
			<ListItem component={Link} to="${this.topic}/${thread.id}" key=$key button onClick=${this.openDialog.bind(thread.id)}>
				<ListItemAvatar>
					<Avatar>
						<Image />
					</Avatar>
				</ListItemAvatar>
				<ListItemText primary=${question.answer} secondary=${date} />
			</ListItem>
			'));
		}

		if (output.length == 0) {
			output.push(jsx('<ListItem key={0}>No threads.</ListItem>'));
		}

		return output;
	}

	override function render():ReactFragment {
		return jsx('
		<Box>
      <List component="nav">
				${this.renderThreads()}
			</List>
			
			<Dialog
				fullScreen
				open=${this.state.dialog_open}
				onClose={this.closeDialog}
    	>
				<ThreadDisplay topic=${this.topic} content=${this.thread} closeDialog=${this.closeDialog} />
			</Dialog>
			<Location />
		</Box>
		');

	}

	var thread(get, never):TStoreContent;
	function get_thread() {
		return this.state.threads[this.state.id];
	}

	var classes(get, never):TClasses;

	function get_classes():TClasses {
		return props.classes;
	}

	var topic(get, never):String;

	function get_topic():String {
		return this.props.topic;
	}

	var db(get, never):Firestore;

	private inline function get_db() {
		return Firestore.getFirestore(FirebaseApp.getApp());
	}

	function getResponseFromSession(title:TStoreContent, state:HelpState) {
		for (item in title.session.questions) {
			if (item.state == state) {
				return item;
			}
		}
		return null;
	}
}
